<template>
    <Dialog
        :header="documentById ? 'Edit Document' : 'Upload Document'"
        v-model:visible="showModal"
        :modal="true"
        :closable="false"
        contentClass="pb-0"
        :style="{ width: '75vw' }"
    >
        <form>
            <div class="container container-width mt-3 pb-3">
                <div class="row align-items-center">
                    <div :class="documentById ? 'col-md-6' : 'col-md-12'">
                        <label for="documentName" class="fw-bold">Name</label>
                        <input
                            id="documentName"
                            type="text"
                            v-model.lazy="v$.form.documentName.$model"
                            :disabled="uploadingFile"
                            class="w-100 form-control p-2"
                            placeholder="Document Name"
                        />
                        <span
                            v-if="v$.form.documentName.$error && v$.form.documentName.required && v$.form.documentName.required.$invalid"
                            class="text-danger"
                            >{{ messages.documentValidation.documentRequired }}
                        </span>
                        <span
                            v-else-if="v$.form.documentName.$error && v$.form.documentName.maxLength &&
                            v$.form.documentName.maxLength.$invalid" class="text-danger"
                            >{{ messages.documentValidation.documentLength }}
                        </span>
                        <span
                            v-else-if="
                                v$.form.documentName.$error &&
                                v$.form.documentName.asyncValidator &&
                                form.documentName &&
                                v$.form.documentName.asyncValidator.$invalid
                            "
                            class="text-danger"
                            >{{ messages.documentValidation.documentNameAlreadyExist }}
                        </span>
                    </div>
                    <div class="col-md-4 offset-2 mb-3" v-if="documentById">
                        <label class="fw-bold mb-2">Status</label>
                        <div>
                            <RadioButton id="rbActive" name="state" :value="0" v-model="form.state" />
                            <label for="rbActive" class="ms-2 me-3 fw-bold">Active</label>
                            <RadioButton class="ms-5" id="rbArchive" name="state" :value="1" v-model="form.state" />
                            <label for="rbArchive" class="ms-2 fw-bold">Archived</label>
                        </div>
                    </div>
                </div>
                <div class="row my-3 align-items-center">
                    <div class="col-md-8">
                        <div class="row">
                            <label for="fileName" class="fw-bold">File</label>
                            <div class="col-md-9 pe-1">
                                <span @click="onFileNameClick">
                                    <InputText
                                        :role="fileName ? 'button' : ''"
                                        id="fileName"
                                        class="w-100"
                                        :class="{ 'color-lavender-purple': form.link }"
                                        type="text"
                                        v-model="v$.fileName.$model"
                                        disabled
                                    />
                                </span>
                                <input type="file" class="d-none" accept="image/*, application/pdf" ref="fileInput" @change="onFile" />
                                <span v-if="v$.fileName.$error && v$.fileName.required && v$.fileName.required.$invalid" class="text-danger"
                                    >{{ messages.documentValidation.fileError }}
                                </span>
                            </div>
                            <div class="col-md-1 pt-1">
                                <a
                                    v-show="false"
                                    v-if="form.link && fileName"
                                    ref="downLoadLink"
                                    :href="form.link"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{{ fileName }}</a
                                >
                                <Button
                                    :icon="'pi ' + (fileName ? 'pi-minus' : 'pi-plus')"
                                    :title="(fileName ? 'Change' : 'Upload') + ' document'"
                                    :class="fileName ? 'p-button-danger' : 'p-button-primary'"
                                    @click="onFileClick"
                                    :disabled="uploadingFile"
                                    id="plusButton"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 mt-3" v-if="fileExtension">
                        <span class="fw-bold me-2">Type</span>
                        {{ Utility.getFileType(fileExtension)}}
                    </div>
                    <div class="col-md-2 mt-3" v-if="form.fileSize"><span class="fw-bold me-2">Size</span>{{ form.fileSize }}</div>
                </div>
                <div class="row">
                    <div class="col-md-8 mb-2">
                        <label class="fw-bold">Applies To</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 pe-0 field-radiobutton">
                        <RadioButton id="rbAll" name="level" :value="0" @change="onLevelChange" v-model="form.level" />
                        <label for="rbAll" class="ps-2">All</label>
                    </div>
                    <div class="col-md-2 field-radiobutton">
                        <RadioButton id="rbSpecify" name="level" :value="1" @change="onLevelChange" v-model="form.level" />
                        <label for="rbSpecify" class="ps-2">Specify</label>
                    </div>
                </div>
                <div class="row ps-0" style="max-height: 13vw">
                    <div class="col-md-6 overflow-auto ps-0" style="max-height: 13vw">
                        <span class="text-danger ms-3" v-if="locationTreeValidation">
                            {{messages.documentValidation.locationTreeValidation}}
                        </span>
                        <LocationSelection
                            :showTree="Boolean(form.level)"
                            :selectedLocations="selectedKeys"
                            @locationsSet="(locations) => locations = locations"
                            @selectTree="applyTree"
                        />
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <hr />
            <Button class="float-start p-button-danger" v-if="form.id" label="DELETE" @click="deleteDocument" :disabled="uploadingFile" />
            <Button type="submit" label="SAVE" @click="saveDocument" :disabled="uploadingFile" />
            <Button label="CANCEL" @click="$emit('closeModal')" class="p-button-outlined" :disabled="uploadingFile" />
        </template>
        <ProgressSpinner class="p-component-overlay position-fixed w-100 h-100" strokeWidth="4" v-if="uploadingFile" />
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { maxLength, required, helpers } from '@vuelidate/validators';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import LocationSelection from '../../../../shared/LocationSelection.vue';
import { toasterTime, messages, locationTreePrefix, userActivities, toasterType } from '../../../../shared/constants';
import { Utility, UserActivity } from '../../../../shared/utils';
import { DocumentService, FileStorageService } from '../../../../apis';
const { withAsync } = helpers;

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'UploadDocumentForm',
    components: {
        Dialog,
        InputText,
        Button,
        ProgressSpinner,
        RadioButton,
        LocationSelection
    },
    props: {
        documentById: Object,
        documents: Object
    },
    data() {
        return {
            userActivities: userActivities,
            nodes: [],
            selectedKeys: this.getSelectedKeys(),
            form: {
                id: this.documentById?.id,
                documentName: this.documentById ? this.documentById.name : '',
                link: this.documentById ? this.documentById.link : '',
                fileSize: this.documentById ? this.documentById.size : '',
                fileType: this.documentById ? this.documentById.type : '',
                state: this.documentById?.state || 0,
                level: this.documentById ? this.documentById.associations.level : '' || 1
            },
            fileExtension: this.documentById ? this.documentById.fileExtension : '',
            file: null,
            fileName: this.documentById?.filename,
            showModal: true,
            uploadingFile: false,
            locations: new Map(),
            documentNames: [],
            messages,
            fileNameById: this.documentById?.filename,
            initialLocValidationFlag: true,
            Utility
        };
    },
    validations() {
        return {
            form: {
                documentName: { required, maxLength: maxLength(50), asyncValidator: withAsync(this.checkDocumentNameExist) }
            },
            fileName: { required }
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        getSelectedKeys() {
            const selectedKeys = {};
            this.filterObj = this.documentById?.associations || {};
            for (let [key, value] of Object.entries(this.filterObj)) {
                key = key.slice(0, -1);
                Array.isArray(value) && value.forEach(id => id && (selectedKeys[locationTreePrefix[key] + id] = { checked: true }));
            }
            return selectedKeys;
        },
        throwDataPullError({ detail = 'Error occured while pulling Location Datas.' }) {
            this.$toast.add({
                severity: 'error',
                closable: false,
                detail: detail,
                life: toasterTime
            });
        },
        async checkDocumentNameExist() {
            if (this.documentNames.length === 0) {
                this.documents.forEach(doc => {
                    this.documentNames.push({
                        id: doc.id,
                        name: doc.name.toLowerCase()
                    });
                });
            }
            if (this.form.documentName) {
                const tempDocName = Utility.removeInBetweenSpaces(this.form.documentName).trim();
                const existingDoc = this.documentNames.find(x => x.name == tempDocName && x.id != this.form.id);
                if (existingDoc) {
                    return false;
                } else {
                    const value = await DocumentService.checkDocumentNameExist(tempDocName, this.documentById ? this.form.id : 0);
                    return value ? false : true;
                }
            }
            return false;
        },
        onFileClick() {
            if (this.fileName) {
                this.$refs.fileInput.value = null;
                this.fileName = null;
                this.file = null;
                this.form.fileSize = null;
                this.fileExtension = null;
                this.form.link = null;
                return;
            }
            this.$refs.fileInput.click();
        },
        onFileNameClick() {
            this.$refs.downLoadLink?.click();
        },
        onFile(e) {
            this.file = e.target.files[0];
            this.fileName = this.file.name;
            this.fileExtension = this.file.type;
            this.form.fileSize = this.file.size / 1000 + ' KB';
            e.target.value = null;
        },
        onLevelChange() {
            if (this.form.level) {
                this.selectedKeys = { ...this.selectedKeys, ...this.selectedKeysBhk };
                this.filterObj = { ...this.filterObj, ...this.filterObjBhk };
                return;
            } else {
                this.locationTreeValidation = null;
            }
            this.selectedKeysBhk = { ...this.selectedKeys };
            this.filterObjBhk = { ...this.filterObj };
            this.selectedKeys = {};
            this.filterObj = {
                boroughs: [],
                districts: [],
                buildings: [],
                locations: []
            };
        },
        async saveDocument() {
            this.uploadingFile = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid || this.locationTreeValidation) {
                this.uploadingFile = false;
                return;
            } else if (this.initialLocValidationFlag && this.form.level) {
                this.locationTreeValidation = messages.documentValidation.locationTreeValidation;
                this.uploadingFile = false;
                return;
            }
            if (this.fileName && this.fileName != this.fileNameById) {
                if (this.documentById) {
                    await FileStorageService.deleteFile(this.documentById.link);
                }
                this.fileName = Utility.uniqueFileName(this.fileName);
                try {
                    const downloadUrl = await FileStorageService.uploadFile(this.fileName, this.file);
                    await this.saveDocumentInDB(downloadUrl);
                } catch (err) {
                    this.throwDataPullError({ detail: messages.uploadingFileValidation.uploadingFileFailed });
                    this.uploadingFile = false;
                }
            } else {
                await this.saveDocumentInDB();
            }
        },
        applyTree(selections, filterObj) {
            this.selectedKeys = selections;
            this.filterObj = filterObj;
            const data = Object.values(this.filterObj);
            if (this.form.level && data[3].length == 0) {
                this.locationTreeValidation = messages.documentValidation.locationTreeValidation;
                this.initialLocValidationFlag = true;
            } else {
                this.locationTreeValidation = null;
                this.initialLocValidationFlag = false;
            }
        },
        async saveDocumentInDB(downloadURL) {
            const docData = {
                name: Utility.removeInBetweenSpaces(this.form.documentName).trim(),
                filename: this.fileName,
                link: downloadURL || this.form.link,
                fileExtension: this.fileExtension,
                type: Utility.getFileTypeValue(this.fileExtension),
                size: this.form.fileSize,
                state: this.form.state,
                associations: {
                    boroughs: [],
                    districts: [],
                    buildings: [],
                    locations: [],
                    ...this.filterObj,
                    level: this.form.level
                }
            };
            if (!this.documentById) {
                const createDocData = {
                    ...docData,
                    info: {
                        ...UserActivity.getActivity(userActivities.created),
                        ...UserActivity.getActivity(userActivities.updated)
                    }
                };
                try {
                    await DocumentService.create(createDocData);
                    this.showToaster(messages.documentValidation.documentCretedSuccess, toasterType.success);
                    this.$emit('closeModal');
                } catch (err) {
                    this.throwDataPullError({ detail: messages.documentValidation.documentCreateFailed });
                    this.uploadingFile = false;
                }
            } else {
                const editDocData = {
                    ...docData,
                    info: {
                        ...UserActivity.getActivity(userActivities.updated),
                        ...UserActivity.getActivity(userActivities.archived, this.form.state)
                    }
                };
                try {
                    await DocumentService.update(this.form.id, editDocData);
                    this.showToaster(messages.documentValidation.documentUpdateSuccess, toasterType.success);
                    this.$emit('closeModal');
                } catch (err) {
                    this.throwDataPullError({ detail: messages.documentValidation.documentUpdateFailed });
                    this.uploadingFile = false;
                }
            }
        },
        async deleteDocument() {
            this.$confirm.require({
                message: messages.documentValidation.deleteConfirmation.message,
                header: messages.documentValidation.deleteConfirmation.header,
                accept: async () => {
                    this.uploadingFile = true;
                    try {
                        await FileStorageService.deleteFile(this.documentById.filename);
                        await DocumentService.delete(this.documentById.id);
                        this.showToaster(messages.documentValidation.documentDeleteSuccess, toasterType.success);
                        this.$emit('closeModal');
                    } catch (err) {
                        this.uploadingFile = false;
                        this.showToaster(messages.documentValidation.documentDeleteFailed, toasterType.error);
                    }
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        }
    },
    emits: ['closeModal']
};
</script>

<style scoped>
#plusButton {
    width: 45px;
}
</style>
<style>
.p-progress-spinner-svg {
    width: 100px !important;
}
</style>
