<template>
    <Dialog header="Filter" @hide="$emit('closeFilter')" position="topright" v-model:visible="displayFilter"
    :style="{width: '25.25rem'}" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500">State</div>
            <Dropdown class="w-100" v-model="selectedState" :options="documentState"
            optionLabel="name" optionValue="code" placeholder="Select a State" />
        <div class="text-muted fs-5 font-weight-500 mt-4 mb-2">Type</div>
        <div class="field-checkbox mt-2" v-for="(type,index) in documentType" :key="index">
            <Checkbox :value="type.code" v-model="selectedTypes"/>
            <label class="size14 fw-normal ms-3">{{type.name}}</label>
        </div>
        <div class="text-muted fs-5 font-weight-500 mt-4">Locations</div>
        <div class="field-checkbox margin-top-12">
            <LocationSelection :showTree="true" v-on:selectTree="applyTree" :selectedLocations="selectedLocations" />
        </div>
        <Button class="justify-content-center button-color-lavender-purple mt-5 w-100"
        @click="applyfilter()">APPLY</Button>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { DocumentState } from '../../../../shared/enums/document-state';
import { DocumentType } from '../../../../shared/enums/document-type';
import LocationSelection from '../../../../shared/LocationSelection.vue';

export default {
    name: 'DocumentsFilter',
    components: {
        Button,
        Dialog,
        Dropdown,
        Checkbox,
        LocationSelection
    },
    created() {
        for (const [key, value] of Object.entries(DocumentType)) {
            this.documentType.push({ name: key, code: value });
        }
    },
    props: {
        displayFilter: Boolean
    },
    data() {
        return {
            selectedState: null,
            selectedTypes: [],
            documentState: Object.keys(DocumentState)
                .map(key => ({ name: String(key), code: DocumentState[key] })),
            documentType: [],
            selectedLocations: [],
            filterObj: {}
        };
    },
    methods: {
        clearFilters() {
            this.selectedState=null;
            this.selectedTypes=[];
            this.selectedLocations = [];
            this.filterObj = {};
            this.applyfilter();
        },
        applyfilter() {
            this.$emit('applyFilter', parseInt(this.selectedState), this.selectedTypes, this.filterObj);
        },
        applyTree(selections, filterObj) {
            this.selectedLocations = selections;
            this.filterObj = filterObj;
        }
    }
};
</script>
