<template>
    <div class="container container-width">
        <div class="text-end mt-2">
            <Button class="button-color-lavender-purple create-new-button" label="UPLOAD NEW" @click="showModal = true" />
        </div>
            <DataTable
                class="mt-3"
                :value="documents"
                :paginator="true"
                :rows="rows"
                :rowsPerPageOptions="rowPerPageOptions"
                :loading="loading"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                responsiveLayout="scroll"
                selectionMode="single"
                sortField="name"
                :sortOrder="sortOrder.Ascending"
            >
                <template #header>
                    <Button class="p-button-text" @click="displayFilter = true">
                        <img src="../../../../assets/filter-icon.png" />
                        <span class="ms-2 color-lavender-purple">Filter</span>
                    </Button>
                </template>
                <Column field="name" header="Document Name" class="table-column-title" sortable>
                    <template #body="{ data }">
                        <span
                            class="ms-0 legend-key "
                            :class="
                                data.state == DocumentState.Active ? 'lavender-purple-background' : 'gray-background'
                            "
                        ></span>
                        <a
                            class="table-first-column"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="data.name"
                            @click="(documentById = data, showModal = true)"
                        >
                            {{ data.name }}
                        </a>
                    </template>
                </Column>
                <Column field="typeName" header="Type" sortable/>
                <Column field="size" header="Size" sortable/>
                <template #paginatorstart></template>
            </DataTable>
            <DocumentsFilter :displayFilter="displayFilter" @applyFilter="applyFilter" @closeFilter="displayFilter = false;" />
    </div>
    <div v-if="showModal">
        <UploadDocumentForm @closeModal="(showModal = false, documentById = null)" :documentById="documentById" :documents="documents" />
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import DataTable from 'primevue/datatable';
import Button from 'primevue/button';
import Column from 'primevue/column';
import UploadDocumentForm from '../upload-Document/UploadDocumentForm.vue';
import { DocumentState } from '../../../../shared/enums/document-state';
import { rows, rowPerPageOptions, toasterTime, messages, sortOrder } from '../../../../shared/constants/constants';
import DocumentsFilter from './DocumentsFilter.vue';
import { FilterMatchMode } from 'primevue/api';
import { DocumentService } from '../../../../apis';
import { Utility } from '../../../../shared/utils';
import { DocumentType } from '../../../../shared/enums/index';
export default {
    name: 'AllDocuments',
    components: {
        DataTable,
        Column,
        Button,
        UploadDocumentForm,
        DocumentsFilter
    },
    data() {
        return {
            sortOrder,
            Utility,
            DocumentType,
            documents: ref([]),
            showModal: false,
            documentById: null,
            loading: false,
            rows: rows,
            rowPerPageOptions: rowPerPageOptions,
            DocumentState,
            displayFilter: false,
            filters: {
                'state': { value: null, matchMode: FilterMatchMode.EQUALS },
                'type': { value: [], matchMode: FilterMatchMode.IN },
                'associations.buildings': { value: [], matchMode: 'array-contains-any' }
            }
        };
    },
    created() {
        this.fetchDocuments();
    },
    methods: {
        async fetchDocuments() {
            this.loading = true;
            try {
                this.documents = await DocumentService.getSnapshot(this.filters);
                watchEffect(() => {
                    if (this.documents?.length) {
                        this.documents.forEach(doc => (doc.typeName = Utility.getKeyByValue(DocumentType, doc.type)));
                    }
                });
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    closable: false,
                    detail: messages.documentValidation.documentsFetchingFailed,
                    life: toasterTime
                });
            } finally {
                this.loading = false;
            }
        },
        applyFilter(state, types, { buildings }) {
            if (types.length && buildings.length) {
                this.$toast.add({
                    severity: 'error',
                    closable: false,
                    detail: messages.documentValidation.filterError,
                    life: toasterTime
                });
                return;
            }
            this.filters.state.value = state;
            this.filters.type.value = types;
            this.filters['associations.buildings'].value = buildings;
            this.fetchDocuments();
            this.displayFilter = false;
        }
    }
};
</script>
